<template>
  <v-card>
    <v-card-title class="headline"
      >{{ this.id ? "Редактирование " : "Добавление " }} пропуска</v-card-title
    >
    <v-card-text>
      <v-alert v-if="message" type="error">{{ message }}</v-alert>
      <v-row>
        <v-col cols="4">
          <v-text-field
            label="ГРНЗ"
            outlined
            v-model="pass.plate"
          ></v-text-field>
        </v-col>
        <v-col cols="4"
          ><v-text-field
            label="EAN13"
            outlined
            v-model="pass.ean13"
          ></v-text-field
        ></v-col>
        <v-col cols="4">
          <v-menu
            v-model="menuExpireDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="pass.expireDate"
                label="Дата истечения"
                prepend-inner-icon="mdi-calendar"
                append-icon="clear"
                readonly
                outlined
                @click:append="pass.expireDate = ''"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="pass.expireDate"
              @input="menuExpireDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <!-- <v-col cols="4">
          <v-text-field
            v-model="pass.entryTimeFrom"
            label="Время въезда, с"
            hint="Введите время или оставте пустым"
            :rules="[rules.time]"
            clearable
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="pass.entryTimeTo"
            label="Время въезда, по"
            hint="Введите время или оставте пустым"
            :rules="[rules.time]"
            clearable
            outlined
          ></v-text-field>
        </v-col> -->

        <v-col cols="4">
          <v-text-field
            label="Компания"
            outlined
            v-model="pass.company"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="ФИО"
            outlined
            v-model="pass.fullName"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-textarea
        label="Комментарий"
        outlined
        v-model="pass.comment"
      ></v-textarea>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :to="{ name: 'passes' }" text>Отмена</v-btn>
      <v-btn @click="onSavePass" color="primary">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import passesApi from "@/api/passes";
import commonMixin from "@/mixins/commonMixins";
import moment from "moment";

export default {
  props: ["id"],
  mixins: [commonMixin],
  data() {
    return {
      pass: {
        plate: "",
        ean13: "",
        expireDate: "",
        entryTimeFrom: "",
        entryTimeTo: "",
        comment: "",
        isActive: true,
      },

      menuExpireDate: false,
      message: "",
    };
  },
  methods: {
    onSavePass() {
      const pass = Object.assign({}, this.pass);
      pass.id = this.id;
      pass.expireDate = this.serialize(pass.expireDate, "YYYY-MM-DD");
      //pass.entryTimeFrom = this.serialize(pass.entryTimeFrom, "HH:mm");
      //pass.entryTimeTo = this.serialize(pass.entryTimeTo, "HH:mm");

      const promise = this.id ? passesApi.edit(pass) : passesApi.add(pass);

      promise.then(() => this.$router.push({ name: "passes" }));
    },
    serialize: (val, format) => (val ? moment(val, format).toDate() : null),
    deserialize: (val, format) => {
      return val ? moment(val).format(format) : null;
    },
  },
  created() {
    if (this.id) {
      passesApi.get(this.id).then((r) => {
        const pass = r.data;
        pass.expireDate = this.deserialize(pass.expireDate, "YYYY-MM-DD");
        //pass.entryTimeFrom = this.deserialize(pass.entryTimeFrom, "HH:mm");
        //pass.entryTimeTo = this.deserialize(pass.entryTimeTo, "HH:mm");

        this.pass = pass;
      });
    }
  },
};
</script>

<style>
</style>