<template>
  <v-card>
    <v-card-title>
      Выписка
      <v-spacer />
        <v-btn depressed class="mr-5" @click="downloadCsv">
          <v-icon left dark>save_alt</v-icon>
          Скачать CSV
        </v-btn>
      <div>
        <div class="font-weight-light">
          Сумма платежей: {{ totalAmount | money }} ₸
        </div>
      </div>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="statements"
      :items-per-page="pageCount"
      :footer-props="footerProps"
      :server-items-length="totalCount"
      :options.sync="options"
    >
      <template v-slot:item.entryDateTime="{ item }">
        {{ item.entryDateTime | fulldate }}
      </template>
      <template v-slot:item.exitDateTime="{ item }">
        {{ item.exitDateTime | fulldate }}
      </template>
      <template v-slot:item.minutes="{ item }">
        {{ item.minutes | duration }}
      </template>
      <template v-slot:item.amount="{ item }">
        {{ item.amount | money }} ₸
      </template>
      <template v-slot:item.paymentType="{ item }">
        {{ getPaymentType(item) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      options: {},
      headers: [
        { text: "ГРНЗ", value: "plate" },
        { text: "EPC", value: "epc" },
        { text: "Дата и время въезда", value: "entryDateTime" },
        { text: "Дата и время выезда", value: "exitDateTime" },
        { text: "Длительность", value: "minutes" },
        { text: "Сумма", value: "amount" },
        { text: "Тип оплаты", value: "paymentType" },
      ],
      footerProps: {
        "items-per-page-options": [5, 10, 15, 20],
      },
    };
  },
  computed: {
    ...mapState({
      statements: (state) => state.statements.statements,
      totalCount: (state) => state.statements.totalCount,
      totalAmount: (state) => state.statements.totalAmount,
      pageCount: (state) => state.statements.criteria.count,
    }),
  },
  methods: {
    getPaymentType(item) {
      if (item.paymentType === 1) return "Designa";
      if (item.paymentType === 2) return "Пропуск";
      if (item.paymentType === 3) return "Kaspi";
      if (item.paymentType === 4) return "MegaPass";
      return "Unknown";
    },
    ...mapActions("statements", ["paginate", "downloadCsv"]),
  },
  watch: {
    options: {
      handler(data) {
        this.paginate({ page: data.page, count: data.itemsPerPage });
      },
      deep: true,
    },
  },
};
</script>