<template>
  <v-card>
    <v-card-title
      class="headline grey lighten-2 d-flex justify-space-between"
      >{{ title }}</v-card-title
    >
    <v-card-text class="mt-4">{{ text }}</v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" text @click="$emit('confirm')">Да</v-btn>
      <v-btn text @click="$emit('close')">Отмена</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["title", "text"],
};
</script>