var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.passes,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.createDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(item.createDate)))])]}},{key:"item.expireDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.expireDate))+" ")]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.isActive ? "Да" : "Нет"))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary","to":{ name: 'passEdit', params: { id: item.id } }}},[_c('v-icon',[_vm._v("edit")])],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.askToDeleteClick(item)}}},[_c('v-icon',[_vm._v("delete_forever")])],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.passToDelete)?_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Удалить пропуск?")]),_c('v-card-text',[_vm._v("Пропуск "+_vm._s(_vm.passToDelete.plate || _vm.passToDelete.ean13)+" будет удален из системы")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Отмена")]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.deleteClick}},[_vm._v("Да")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }