<template>
  <div>
    <StatementsFilter class="mt-4" />
    <StatementsTable class="mt-8" />
  </div>
</template>

<script>
import StatementsFilter from "./StatementsFilter";
import StatementsTable from "./StatementsTable";

import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { mapActions } from "vuex";

export default {
  components: {
    StatementsFilter,
    StatementsTable,
  },
  data() {
    return { connection: null };
  },
  methods: mapActions("statements", ["fetchStatements"]),
  created() {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${process.env.VUE_APP_API_BASE_URL}/hubs/owner`, {
        accessTokenFactory: () => localStorage.getItem("token"),
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Error)
      .build();
  },
  mounted() {
    this.fetchStatements();

    this.connection.start();
    this.connection.on("new-statement", () => this.fetchStatements());
  },
};
</script>