<template>
  <v-card>
    <v-card-title
      class="headline grey lighten-2 d-flex justify-space-between"
      primary-title
      >Добавление компании</v-card-title
    >

    <v-card-text class="text--primary mt-3">
      <v-text-field v-model="name" label="Название" outlined></v-text-field>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="submit" :disabled="name.length < 2" color="red"
        >Создать</v-btn
      >
      <v-btn text @click="close">Отмена</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      name: "",
    };
  },
  methods: {
    submit() {
      const merchant = { name: this.name };
      this.addMerchant(merchant).then(() => {
        this.showAlert({ message: "Компания успешно создана" });
        this.close();
      });
    },
    close() {
      this.$emit("close");
      this.name = "";
    },
    ...mapActions("merchants", ["addMerchant"]),
    ...mapActions(["showAlert"]),
  },
};
</script>

<style>
</style>