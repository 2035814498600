<template>
  <v-card>
    <v-card-title>
      Компании
      <v-spacer></v-spacer>
      <v-btn @click="dialogAdd = true" icon color="red">
        <v-icon>add</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Название</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="merchant in merchants" :key="merchant.id">
              <td
                @click="select(merchant)"
                :class="{
                  'blue--text text--darken-4':
                    selected && merchant.id === selected.id,
                }"
              >
                {{ merchant.name }}
              </td>
              <td class="text-right">
                <v-btn
                  @click="askToDelete(merchant)"
                  :disabled="merchant.qrCodesCount > 0"
                  icon
                  color="red"
                >
                  <v-icon>delete_forever</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-dialog v-model="dialogAdd" width="400">
      <MerchantAdd @close="dialogAdd = false" />
    </v-dialog>

    <v-dialog v-model="dialogDelete" width="400">
      <ConfirmDialog
        title="Удаление компании"
        :text="`Вы действительно хотите удалить компанию ${
          merchantToDelete && merchantToDelete.name
        }?`"
        @confirm="confirmDelete(merchantToDelete.id)"
        @close="dialogDelete = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import ConfirmDialog from "@/components/ConfirmDialog";
import MerchantAdd from "./MerchantAdd";
import { mapState, mapActions } from "vuex";

export default {
  components: { ConfirmDialog, MerchantAdd },
  data() {
    return {
      dialogAdd: false,
      dialogDelete: false,
      merchantToDelete: null,
      selected: null,
    };
  },
  computed: mapState({
    merchants: (state) => state.merchants.merchants,
  }),
  methods: {
    select(merchant) {
      this.selectMerchant(merchant);
      this.selected = merchant;
    },
    askToDelete(merchant) {
      this.merchantToDelete = merchant;
      this.dialogDelete = true;
    },
    confirmDelete(id) {
      this.deleteMerchant(id).then(() => {
        this.showAlert({ message: "Компания успешно удалена" });
        this.dialogDelete = false;
      });
    },
    ...mapActions("merchants", [
      "fetchMerchants",
      "selectMerchant",
      "deleteMerchant",
    ]),
    ...mapActions(["showAlert"]),
  },
  beforeMount() {
    this.fetchMerchants();
  },
};
</script>